import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { mapRemarkToPage, progressOnScroll, setOn } from "../helpers";
import {
  Layout,
  ProgressNavbar,
  SectionWrapper,
  PreviewCompatibleImage,
  HTMLBody,
  ArtistCard,
  Icon,
  ReleaseCard,
} from "../components";
import { Release, PageProps, Artist, PressRelease, LinkType } from "../models/";
import "../styles/_page.scss";
import "../styles/_press.scss";
import YoutubeVideo from "../components/YoutubeVideo/YoutubeVideo";
import { PasswordProtectedPage } from "../components/Press/PasswordProtectedPage";

export const PressReleasePageTemplate: React.FC<PageProps<PressRelease>> = ({
  content,
  isPreviewMode,
  data: {
    title,
    artist,
    created_at,
    modified_at,
    featuredimage,
    hyperLinks,
    youtube_links,
    youtube_release_link,
    infoTitle,
    dsp,
    release,
  },
}) => {
  const initialNavItems: any = {
    main: {
      id: "main",
      text: title,
      ref: React.useRef<HTMLDivElement>(null),
    },
  };
  const [scrollNavData, setScrollNavData] = React.useState(initialNavItems);

  const scrollEvent = (): void => {
    progressOnScroll(scrollNavData, setScrollNavData);
  };

  const { releaseDsp } = {
    get releaseDsp() {
      return (release?.dsp.links ?? ([] as LinkType[])).concat(dsp.links);
    },
  };

  React.useEffect(() => {
    // pageIntroFadeAnimation();
    setOn("main", scrollNavData, setScrollNavData);
    window.addEventListener("scroll", scrollEvent);

    return () => {
      window.removeEventListener("scroll", scrollEvent);
    };
  }, []);

  return (
    <div className="page press-page" ref={initialNavItems.main.ref}>
      {isPreviewMode ? null : <ProgressNavbar data={scrollNavData} single />}

      <div className="press-rel-head" id="home">
        <SectionWrapper
          className="press-rel-head__title press-rel__hea full min-height"
          title={title}
        />

        {youtube_release_link ? (
          <YoutubeVideo title={title} url={youtube_release_link} />
        ) : typeof featuredimage !== "string" ? (
          <div className="press-rel-head__image">
            <PreviewCompatibleImage
              imageInfo={{
                image: featuredimage,
                alt: `${title}`,
                className: "test",
              }}
            />
          </div>
        ) : (
          <div
            className="press-rel-head__image"
            style={{
              background: `url(${featuredimage}) no-repeat`,
              backgroundSize: "cover",
            }}
          />
        )}
      </div>

      <div className="page__wrapper press-page" id="main">
        {releaseDsp && releaseDsp?.length ? (
          <SectionWrapper
            className="full container-width-s min-height release__info__dsp"
            style={{ margin: "1rem auto 0" }}
          >
            <ul className="taglist wrap">
              {releaseDsp.map(({ type, url }, i) => (
                <li key={type + `dsp` + i}>
                  <a href={url} target="_blank" className="link">
                    <span>
                      {type}
                      <Icon
                        name={
                          ["Listen", "Pre Save"].includes(type) ? "url" : type
                        }
                      />
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </SectionWrapper>
        ) : null}

        {content && (
          <SectionWrapper
            title={infoTitle}
            className="section__small min-height full"
          >
            <HTMLBody content={content} className="page__wrapper__content" />
          </SectionWrapper>
        )}

        <div className="press-grid">
          <div className="press-grid__start">
            {!hyperLinks?.length ? null : (
              <SectionWrapper
                title="Links"
                className="section__small min-height full"
              >
                <ul className="page__footer__tags--list flex-column">
                  {hyperLinks.map((tag, i) => (
                    <li key={tag.url + `tag`} style={{ width: "100%" }}>
                      <a
                        href={tag.url}
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>{tag.text}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </SectionWrapper>
            )}
          </div>

          <div className="press-grid__end">
            {!youtube_links?.length ? null : (
              <SectionWrapper
                title={"Video" + (youtube_links.length > 1 ? `s` : "")}
                className="section__small min-height full videos-grid"
              >
                {youtube_links?.map((link, i) => (
                  <YoutubeVideo
                    key={link + i}
                    url={link}
                    title={`${title} Related video`}
                  />
                ))}
              </SectionWrapper>
            )}
          </div>
        </div>

        {/* // Artist / Release Cards */}
        {!artist && !release ? null : (
          <div className="press-grid">
            <div className="press-grid__end">
              {!artist ? null : (
                <SectionWrapper
                  title="Artist"
                  className="section__small min-height full"
                >
                  <ArtistCard
                    artist={artist}
                    slug={artist.slug}
                    className="max-cards-height"
                  />
                </SectionWrapper>
              )}
            </div>
            <div className="press-grid__start">
              {!release ? null : (
                <SectionWrapper
                  title="Release"
                  className="section__small min-height full"
                >
                  <ReleaseCard
                    release={release}
                    slug={release.slug}
                    className="max-cards-height"
                  />
                </SectionWrapper>
              )}
            </div>
          </div>
        )}

        <footer className="page__footer">
          {isPreviewMode ? null : (
            <div className="press-footer">
              {!created_at ? null : <span>Created: {created_at},</span>}
              {!modified_at ? null : <span>Last updated: {modified_at}</span>}
            </div>
          )}
        </footer>
      </div>
    </div>
  );
};

const PressReleasePage = ({
  data: { post, artistMarkdown, releaseMarkdown },
  location,
}) => {
  const press = mapRemarkToPage<PressRelease>(post),
    artist = !artistMarkdown ? null : mapRemarkToPage<Artist>(artistMarkdown),
    release = !releaseMarkdown
      ? null
      : mapRemarkToPage<Release>(releaseMarkdown),
    pageData = { ...press, artist, release };

  return (
    <Layout ignoreNav>
      <Helmet titleTemplate="REAL CVLTVRE | %s">
        <title>{press.title}</title>
        <meta property="og:title" content={`REAL CVLTVRE | ${press.title}`} />
        {post.excerpt && <meta name="description" content={post.excerpt} />}
        {post.excerpt && (
          <meta property="og:description" content={post.excerpt} />
        )}
        {press.featuredimage && (<meta
          property="og:image"
          content={press.featuredimage?.childImageSharp?.fluid.src}
        />)}
      </Helmet>

      <PasswordProtectedPage password={press.password} location={location}>
        <PressReleasePageTemplate data={pageData} content={post.html} />
      </PasswordProtectedPage>
    </Layout>
  );
};

export default PressReleasePage;

export const pageQuery = graphql`
  query PressReleasePageByID(
    $id: String!
    $artistId: String
    $releaseId: String
  ) {
    releaseMarkdown: markdownRemark(id: { eq: $releaseId }) {
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        author
        name
        tags
        dsp {
          links {
            type
            url
          }
        }
        featuredimage {
          childImageSharp {
            fluid(
              maxWidth: 550
              quality: 100
              srcSetBreakpoints: [100, 200, 400, 550]
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    artistMarkdown: markdownRemark(id: { eq: $artistId }) {
      fields {
        slug
      }
      frontmatter {
        stageName
        recapLine
        social {
          links {
            type
            url
          }
        }
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 450, quality: 100, srcSetBreakpoints: [250, 450]) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    post: markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 200)
      frontmatter {
        title
        created_at(formatString: "DD/MM/YYYY")
        modified_at(formatString: "DD/MM/YYYY")
        youtube_release_link
        youtube_links
        infoTitle
        password
        hyperLinks {
          text
          url
        }
        dsp {
          links {
            type
            url
          }
        }
        featuredimage {
          childImageSharp {
            fluid(
              maxWidth: 1200
              srcSetBreakpoints: [250, 500, 800, 1200]
              quality: 100
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
